<template>
	<Page class="page-password-reset-link">
		<LayoutAuth
				title="Reset Password"
				passwordReset="true"
		>

			<v-form
					ref="form"
					v-model="valid"
					lazy-validation
			>
				<v-text-field
						v-model="email"
						label="Email"
						type="email"
						:rules="[
                          validationRules.required,
                          validationRules.email
                        ]"
						required
						color="none"
						@keydown.space.prevent
				></v-text-field>
				<v-btn
						type="submit"
						large
						outlined
						:disabled="!valid || auth.passwordResetLink.isLoading"
						:loading="auth.passwordResetLink.isLoading"
						@click.prevent="onSubmit"
						class="btn-submit"
				>
					Submit
				</v-btn>
			</v-form>

		</LayoutAuth>
	</Page>
</template>

<script>
	import Page from "../components/layouts/LayoutPage";
	import LayoutAuth from "../components/layouts/LayoutAuth";
	import { mixinValidationRules } from "../mixins/mixinValidationRules";
	import {
		mapActions,
		mapState
	} from "vuex";
	import { mixinPaths } from "../mixins/mixinPaths";


	export default {
		name: 'PasswordResetLink',
		components: { LayoutAuth, Page },
		mixins: [
			mixinValidationRules,
			mixinPaths,
		],
		computed: {
			...mapState( [
				'auth'
			] ),
		},
		data() {
			return {
				valid: false,
				email: '',
			};
		},
		methods: {
			...mapActions( [
				'requestPasswordResetLink',
				'requestPasswordResetLinkReset',
			] ),
			onSubmit() {
				if ( this.$refs.form.validate() ) {
					this.$store.dispatch( "requestPasswordResetLink" , { email: this.email.toLowerCase() });
				}
			},
		},
		destroyed() {
			this.$store.dispatch( "requestPasswordResetLinkReset" );
		}
	};
</script>
